var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_c('navbar')], 1), _c('b-container', {
    staticClass: "mb-3"
  }, [_c('br'), _c('br'), _c('h3', {
    staticClass: "text-primary text-center mb-2"
  }, [_vm._v("Pembahasan Soal")]), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('h4', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.hasil.ujian_name))]), _c('b-row', {}, [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-bordered responsive"
  }, [_c('tr', {
    staticClass: "bg-primary text-white",
    attrs: {
      "variant": "primary"
    }
  }, [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Jawaban Kamu")]), _c('th', [_vm._v("Kunci Jawaban")]), _c('th', [_vm._v("Pembahasan")])]), _vm._l(_vm.pembahasan, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', {
      domProps: {
        "innerHTML": _vm._s(_vm.jawabanKamu(item))
      }
    }), _c('td', {
      domProps: {
        "innerHTML": _vm._s(_vm.kunciJawaban(item))
      }
    }), _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }, {
        name: "b-modal",
        rawName: "v-b-modal.pembahasan",
        modifiers: {
          "pembahasan": true
        }
      }],
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.lihat(item, i);
        }
      }
    }, [_vm._v(" Lihat ")])], 1)]);
  })], 2)])])], 1), _c('b-modal', {
    attrs: {
      "modal-class": "modal-primary",
      "id": "pembahasan",
      "title": "Pembahasan",
      "header-bg-variant": "primary",
      "header-text-variant": "light",
      "hide-footer": ""
    }
  }, [_c('b-card-text', [_c('h5', [_vm._v("No. " + _vm._s(_vm.noPembahasan))]), _vm.detailPembahasan.type != 'pauli' ? _c('h6', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailPembahasan.content)
    }
  })]) : _c('h6', [_c('br'), _c('b', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailPembahasan.name)
    }
  })])]), _vm.detailPembahasan.type != 'pauli' ? _c('div', {}, _vm._l(_vm.detailPembahasan.options, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "mt-2"
    }, [_vm.detailPembahasan.type == 'pg' ? _c('div', {}, [_vm.detailPembahasan.answer != item.id ? _c('span', [_vm._v(" " + _vm._s(item.option) + ". " + _vm._s(item.is_correct == 1 ? "Benar" : "Salah") + " "), _c('p', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]) : _c('span', [_vm._v(" " + _vm._s(item.option) + ". " + _vm._s(item.is_correct == 1 ? "Benar" : "Salah") + " "), _c('b', [_vm._v("(Jawaban Kamu)")]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })])]) : _vm.detailPembahasan.type == 'multiple_choice' ? _c('div', {}, [_vm.detailPembahasan.answer != item.id ? _c('span', [_vm._v(" " + _vm._s(item.option) + ". " + _vm._s(item.is_correct == 1 ? "Benar" : "Salah") + " "), _c('p', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]) : _c('span', [_vm._v(" " + _vm._s(item.option) + ". " + _vm._s(item.is_correct == 1 ? "Benar" : "Salah") + " "), _c('b', [_vm._v("(Jawaban Kamu)")]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })])]) : _vm.detailPembahasan.type == 'benar_salah' ? _c('div', {}, [_vm.detailPembahasan.answer != item.id ? _c('span', [_vm._v(" " + _vm._s(item.option) + ". " + _vm._s(item.is_true_or_false) + " "), _c('p', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]) : _c('span', [_vm._v(" " + _vm._s(item.option) + ". " + _vm._s(item.is_true_or_false) + " "), _c('b', [_vm._v("(Jawaban Kamu)")]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })])]) : _vm._e()]);
  }), 0) : _c('div', {}, _vm._l(_vm.detailPembahasan.sub_soal, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "mt-2"
    }, [_c('b', [_vm._v(" " + _vm._s(i + 1) + ". ")]), _vm._v(" "), _c('span', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    }), _vm._v(" : "), _c('b', [_vm._v(" " + _vm._s(item.secret_key) + " ")]), _vm._v(" "), _c('br'), _vm._v(" Jawaban Kamu : "), _c('b', [_vm._v(" " + _vm._s(item.is_correct_answer == 1 ? "Benar" : "Salah") + " ")])]);
  }), 0), _vm.detailPembahasan.pembahasan != null ? _c('div', {
    staticClass: "mt-4"
  }, [_c('h6', [_vm._v("Pembahasan :")]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailPembahasan.pembahasan)
    }
  })]) : _vm._e()])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }